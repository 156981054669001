import styled from 'styled-components';
import { media } from '../../theme/breakpoints';
import { colors } from '../../theme/colors';

export const Container = styled.section`
  width: 100%;
  background: #ffe6a8;
`;
export const ContactWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 70px 0;

  display: flex;
  align-items: center;

  ${media.screen('tablet')} {
    flex-direction: column;
  }

  ${media.screen('mobile')} {
    flex-direction: column;
  }
`;

export const ContactLeft = styled.div`
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.gray_700};
  }

  h2 {
    max-width: 500px;
    font-size: 42px;
    font-weight: 600;
    margin: 16px 0 24px 0;
    color: ${colors.gray_700};
  }

  > p {
    max-width: 480px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 80px;
    color: ${colors.gray_700};
  }

  ${media.screen('tablet')} {
    width: 100%;
    padding: 0 32px;

    span,
    h2,
    p,
    strong {
      text-align: center;
    }

    h2,
    p {
      max-width: 100%;
    }
  }

  ${media.screen('mobile')} {
    width: 100%;
    padding: 0 32px;

    span,
    h2,
    p,
    strong {
      text-align: center;
    }

    h2,
    p {
      max-width: 100%;
    }
  }
`;
export const ContactRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  form {
    width: 100%;
    max-width: 456px;
    height: auto;
    padding: 32px;
    border-radius: 8px;
    background: ${colors.white};

    h5 {
      font-size: 24px;
      color: ${colors.gray_500};
    }

    p {
      font-size: 14px;
      margin: 8px 0 40px 0;
      color: ${colors.gray_300};
    }

    button {
      border: none;
      height: 56px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      margin-top: 24px;
      border-radius: 8px;
      background-color: ${colors.primary};

      transition: filter 0.3s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  ${media.screen('tablet')} {
    width: 100%;
    padding: 0 32px;
    margin-top: 48px;

    form {
      max-width: 100%;
    }
  }

  ${media.screen('mobile')} {
    width: 100%;
    padding: 0 32px;
    margin-top: 48px;

    form {
      max-width: 100%;
    }
  }
`;
export const ContactSocialsWrapper = styled.div`
  margin-top: 24px;

  ${media.screen('tablet')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${media.screen('mobile')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const SocialItem = styled.div`
  &:last-child {
    margin-top: 24px;
  }

  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    width: 24px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: ${colors.gray_100};
  }

  input,
  textarea {
    border: none;
    height: 54px;
    background: #f3f3f3;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 14px;
    outline: none;
    font-family: 'Lato', sans-serif;

    transition: border 0.3s;

    &::placeholder {
      color: ${colors.gray_100};
    }

    &:focus {
      border: 1px solid ${colors.primary};
    }
  }

  textarea {
    height: auto;
    padding: 16px 8px;
    resize: vertical;
  }

  input.error,
  textarea.error {
    border: 1px solid crimson;

    &::placeholder {
      color: crimson;
    }
  }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: crimson;
  font-weight: 600;
  margin-top: 4px;
`;
