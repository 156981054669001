import * as Styles from './styles';

import comoAjudamosImg from '../../assets/como-ajudamos.png';
import editarImg from '../../assets/editar.svg';
import aprovaImg from '../../assets/aprovacao.svg';
import portalImg from '../../assets/portal.svg';
import processoImg from '../../assets/processo.svg';

export function ComoAjudamos() {
  return (
    <Styles.Container id='como-ajudamos'>
      <Styles.WrapperContent>
        <Styles.ContentLeft>
          <h4>Como ajudamos</h4>
          <h2>Veja como a gente pode ajudar sua franquia</h2>
          <p>
            Através da nossa plataforma sua franquia pode crescer cada vez mais
            com os processos automatizados de personalização de artes. A
            plataforma funciona 24h por dia e 7 dias por semana.
          </p>
          <img src={comoAjudamosImg} alt='Como ajudamos' />
        </Styles.ContentLeft>
        <Styles.ContentRight>
          <Styles.Item>
            <img src={editarImg} alt='Customização' />
            <Styles.ItemContent>
              <span>Customização Automatizada</span>
              <p>
                Seus impressos personalizados automaticamente com os dados do
                Franqueado, assim sobra mais tempo para o marketing focar em
                estratégias.
              </p>
            </Styles.ItemContent>
          </Styles.Item>
          <Styles.Item>
            <img src={portalImg} alt='Portal da marca' />
            <Styles.ItemContent>
              <span>Portal da marca</span>
              <p>
                Disponibilize os arquivos da sua marca para download de forma
                prática e segura.
              </p>
            </Styles.ItemContent>
          </Styles.Item>
          <Styles.Item>
            <img src={aprovaImg} alt='Sistema de aprovação' />
            <Styles.ItemContent>
              <span>Sistema de aprovação</span>
              <p>
                Com essa opção ativada, nenhum pedido é aprovado e liberado
                antes de passar pelo coordenador ou aprovador indicado na
                plataforma.
              </p>
            </Styles.ItemContent>
          </Styles.Item>
          <Styles.Item>
            <img src={processoImg} alt='Processos simplificados' />
            <Styles.ItemContent>
              <span>Processos simplificados</span>
              <p>
                Com poucos cliques franqueado pode orçar e comprar diversos
                produtos para sua loja, além de personalizar diversos materiais.
              </p>
            </Styles.ItemContent>
          </Styles.Item>
        </Styles.ContentRight>
      </Styles.WrapperContent>
    </Styles.Container>
  );
}
