import styled from 'styled-components';
import { colors } from '../../theme/colors';
import SobreBg from '../../assets/sobre-img-bg.svg';
import { media } from '../../theme/breakpoints';

export const Container = styled.div`
  width: 100%;
  height: 650px;
  margin-top: 80px;
  background: ${colors.primary} url(${SobreBg}) no-repeat 95%;

  ${media.screen('tablet')} {
    height: fit-content;
    flex-direction: column;
    padding: 56px 0;
  }

  ${media.screen('mobile')} {
    height: fit-content;
    flex-direction: column;
    padding: 56px 0;
  }
`;
export const AboutWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: inherit;
  margin: 0 auto;

  display: flex;
  align-items: center;

  ${media.screen('tablet')} {
    flex-direction: column;
    padding: 0 32px;
  }

  ${media.screen('mobile')} {
    flex-direction: column;
    padding: 0 32px;
  }
`;
export const AboutLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.white};
    margin-bottom: 16px;
  }

  h3 {
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    color: ${colors.gray_900};
    margin-bottom: 16px;
    max-width: 551px;
  }

  p {
    max-width: 480px;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: ${colors.gray_900};

    span {
      font-weight: 700;
    }
  }

  ${media.screen('tablet')} {
    h4,
    h3,
    p {
      text-align: center;
    }

    h3 {
      font-size: 24px;
      line-height: 140%;
    }

    p {
      font-size: 16px;
      line-height: 150%;
    }
  }

  ${media.screen('mobile')} {
    h4,
    h3,
    p {
      text-align: center;
    }

    h3 {
      font-size: 24px;
      line-height: 140%;
    }

    p {
      font-size: 16px;
      line-height: 150%;
    }
  }
`;
export const AboutRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.screen('tablet')} {
    img {
      width: 100%;
      margin-top: 15%;
    }
  }

  ${media.screen('mobile')} {
    img {
      width: 100%;
      margin-top: 15%;
    }
  }
`;
