import styled, { keyframes } from 'styled-components';
import { media } from '../../theme/breakpoints';
import { colors } from '../../theme/colors';

const animate = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  width: 100%;
`;
export const FluxoWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 42px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
    color: ${colors.gray_50};
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 56px;
    color: ${colors.gray_700};
  }

  img {
    width: 80%;
    animation: ${animate} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 0.1s;
  }

  ${media.screen('tablet')} {
    padding: 56px 24px;

    h3 {
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  ${media.screen('mobile')} {
    padding: 56px 24px;

    h3 {
      text-align: center;
    }

    img {
      width: 100%;
    }
  }
`;
