export const colors = Object.freeze({
  primary: '#FBBA18',
  gray_900: '#000000',
  gray_700: '#161616',
  gray_500: '#323238',
  gray_300: '#7C7C8A',
  gray_100: '#8D8D99',
  gray_50: '#878787',
  background: '#F8F7FC',
  white: '#FFFFFF',
});
