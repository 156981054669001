import * as Styles from './styles';

import Logo from '../../assets/bsine-logo.svg';

export function Footer() {
  return (
    <Styles.Container>
      <Styles.FooterWrapper>
        <Styles.FooterSections>
          <img src={Logo} alt='Logo Bsine' />
          <Styles.FooterSectionRight>
            <Styles.FooterSection>
              <h5>Links úteis</h5>
              <p>Solicitar demonstração</p>
            </Styles.FooterSection>
            <Styles.FooterSection>
              <h5>E-mails</h5>
              <a href='https://'>contato@bsine.com</a>
              <a href='https://'>suporte@bsine.com</a>
            </Styles.FooterSection>
            <Styles.FooterSection>
              <h5>Telefones</h5>
              <p>+55 11 3274-5990</p>
              <p>+55 11 99234-8350</p>
            </Styles.FooterSection>
            <Styles.FooterSection>
              <h5>Ajuda</h5>
              <p>Fale conosco</p>
            </Styles.FooterSection>
          </Styles.FooterSectionRight>
        </Styles.FooterSections>
        <Styles.FooterCopyRight>
          <span>{`Copyright © ${new Date().getFullYear()} - Bsine`}</span>
        </Styles.FooterCopyRight>
      </Styles.FooterWrapper>
    </Styles.Container>
  );
}
