import * as Styles from './styles';

import SobreImg from '../../assets/sobre-img.svg';

export function Sobre() {
  return (
    <Styles.Container id='sobre'>
      <Styles.AboutWrapper>
        <Styles.AboutLeft>
          <h4>Quem somos</h4>
          <h3>
            Somos uma plataforma para customização automatizada, impressão e
            logística de materiais de marketing
          </h3>
          <p>
            A Bsine foi criada para facilitar e otimizar o dia a dia de
            franquias e franqueados.
            <span>
              Na Bsine, cada franquia tem sua plataforma exclusiva com DNA da
              sua marca.
            </span>
            Com poucos cliques os franqueados podem personalizar, baixar e
            comprar diversos materiais para suas lojas e receberem em qualquer
            lugar do Brasil.
          </p>
        </Styles.AboutLeft>
        <Styles.AboutRight>
          <img src={SobreImg} alt='Quem somos' />
        </Styles.AboutRight>
      </Styles.AboutWrapper>
    </Styles.Container>
  );
}
