import * as Styles from './styles';

import FluxoImg from '../../assets/fluxo.svg';

export function Fluxo() {
  return (
    <Styles.Container id='fluxo'>
      <Styles.FluxoWrapper>
        <h4>Como funciona?</h4>
        <h3>Uma solução completa para o marketing e gestão de suprimentos.</h3>
        <img src={FluxoImg} alt='Fluxo' />
      </Styles.FluxoWrapper>
    </Styles.Container>
  );
}
