import { createGlobalStyle } from 'styled-components';
import { colors } from '../theme/colors';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    height: 100vh;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
  }

  body, html {
    scroll-behavior: smooth;
  }

  button {
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: ${colors.background};
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${colors.background};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${colors.primary};
  }
`;
