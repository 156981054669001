import styled from 'styled-components';
import { media } from '../../theme/breakpoints';
import { colors } from '../../theme/colors';

export const Container = styled.div`
  background: #323238;
`;
export const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

export const FooterSections = styled.div`
  padding: 48px 0;

  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    img {
      width: 50%;
      margin-bottom: 48px;
    }
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: ${colors.white};
    margin-bottom: 16px;
  }

  p,
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.white};
    margin-bottom: 16px;
  }
  ${media.screen('tablet')} {
    justify-content: center;
    text-align: center;
    margin-bottom: 42px;
  }

  ${media.screen('mobile')} {
    justify-content: center;
    text-align: center;
    margin-bottom: 42px;
  }
`;

export const FooterSectionRight = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 200px);

  ${media.screen('tablet')} {
    grid-template-columns: 1fr;
  }

  ${media.screen('mobile')} {
    grid-template-columns: 1fr;
  }
`;

export const FooterCopyRight = styled.div`
  width: 100%;
  padding: 16px 0;
  margin-top: 32px;
  border-top: 1px solid #7c7c8a;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.white};
  }
`;
