import React from 'react';

import Cliente01 from '../../assets/cliente1.svg';
import Cliente02 from '../../assets/cliente2.svg';
import Cliente03 from '../../assets/cliente3.svg';
import Cliente04 from '../../assets/cliente4.svg';
import Cliente05 from '../../assets/cliente5.svg';
import Cliente06 from '../../assets/cliente6.svg';
import Cliente07 from '../../assets/cliente7.svg';

import * as Styled from './styles';

export function SectionClients() {
  return (
    <Styled.Container>
      <Styled.WrapperContent>
        <h3>Conheça nossos clientes</h3>
        <Styled.SectionClients className='container'>
          <div className='slider'>
            <div className='slide-track'>
              <div className='slide'>
                <img src={Cliente01} alt='Mr Cheney' />
              </div>

              <div className='slide'>
                <img src={Cliente02} alt='Tea Shop' />
              </div>

              <div className='slide'>
                <img src={Cliente03} alt='Sunglass' />
              </div>

              <div className='slide'>
                <img src={Cliente04} alt='Ray Bam' />
              </div>

              <div className='slide'>
                <img src={Cliente05} alt='Ana Capri' />
              </div>

              <div className='slide'>
                <img src={Cliente06} alt='Oakley' />
              </div>

              <div className='slide'>
                <img src={Cliente07} alt='Audibel' />
              </div>

              <div className='slide'>
                <img src={Cliente01} alt='Mr Cheney' />
              </div>

              <div className='slide'>
                <img src={Cliente02} alt='Tea Shop' />
              </div>

              <div className='slide'>
                <img src={Cliente03} alt='Sunglass' />
              </div>

              <div className='slide'>
                <img src={Cliente04} alt='Ray Bam' />
              </div>

              <div className='slide'>
                <img src={Cliente05} alt='Ana Capri' />
              </div>

              <div className='slide'>
                <img src={Cliente06} alt='Oakley' />
              </div>

              <div className='slide'>
                <img src={Cliente07} alt='Audibel' />
              </div>
            </div>
          </div>
        </Styled.SectionClients>
      </Styled.WrapperContent>
    </Styled.Container>
  );
}
