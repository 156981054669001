import { colors } from '../../theme/colors';
import styled from 'styled-components';
import { media } from '../../theme/breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 30.563rem;
  height: 19.438rem;
  border-radius: 0.25rem;
  padding: 3.125rem 1.375rem;
  background: ${colors.gray_500};

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${colors.white};
  }

  .description {
    color: ${colors.white};
  }

  &.active {
    opacity: 0;
    transform: translateY(30.563rem);

    transition: 0.5s ease-in-out;
  }

  ${media.screen('tablet')} {
    width: 100%;
  }

  ${media.screen('mobile')} {
    max-width: 100%;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1.5rem;
`;

export const InfosWrapper = styled.div`
  margin-left: 1rem;

  display: flex;
  flex-direction: column;

  span {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: ${colors.primary};
  }

  p {
    color: ${colors.white};
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
`;
