import React, { ButtonHTMLAttributes } from 'react';

import * as Styles from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width: string;
  height: string;
  colorText: string;
  background: string;
  isOutlined: boolean;
  borderRadius: string;
  text: string;
}

export function Button({
  width,
  height,
  colorText,
  background,
  isOutlined,
  borderRadius,
  text,
  ...rest
}: IButtonProps) {
  return (
    <Styles.Container
      width={width}
      height={height}
      colorText={colorText}
      background={background}
      isOutlined={isOutlined}
      borderRadius={borderRadius}
      {...rest}
    >
      {text}
    </Styles.Container>
  );
}
