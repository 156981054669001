import { Button } from '../../components/Button';
import { colors } from '../../theme/colors';
import * as Styles from './styles';

import Mascote from '../../assets/mascote.svg';
import platform from '../../assets/platform.svg';
import { useContext } from 'react';
import DemoContext from '../../contexts/DemoContext';

export function Hero() {
  const { handleOpenModal } = useContext(DemoContext);
  return (
    <Styles.Container id='home'>
      <Styles.HeroWrapper>
        <Styles.HeroLeft>
          <h1>
            Otimize o marketing da sua franquia
            <br /> com a bsine
          </h1>
          <p>
            Tenha <span>personalização automatizada</span>, entrega e logística
            em um só lugar.
          </p>

          <Button
            background={colors.primary}
            height='45px'
            width='264px'
            borderRadius='21px'
            colorText={colors.gray_700}
            isOutlined={false}
            text='Solicite uma demonstração'
            onClick={handleOpenModal}
          />
        </Styles.HeroLeft>

        <Styles.HeroRight>
          <img src={Mascote} alt='Mascote Bsine' className='mascote' />
          <img src={platform} alt='Plataforma' className='platform' />
        </Styles.HeroRight>
      </Styles.HeroWrapper>
    </Styles.Container>
  );
}
