import styled, { keyframes } from 'styled-components';
import { colors } from '../../theme/colors';
import { media } from '../../theme/breakpoints';

const animateTitle = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const animateSubTitle = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const animateButton = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }to {
    opacity: 1;
    transform: scale(1);
  }
`;
const animateMascote = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const animatePlatform = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 440px;
  margin-top: 80px;
  background: ${colors.background};

  ${media.screen('tablet')} {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
`;
export const HeroWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: inherit;
  margin: 0 auto;

  display: flex;
  align-items: center;

  ${media.screen('tablet')} {
    flex-direction: column;
    align-items: center;
  }

  ${media.screen('mobile')} {
    flex-direction: column;
    align-items: center;
  }
`;
export const HeroLeft = styled.div`
  max-width: 466px;
  height: inherit;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: 600;
    font-size: 51px;
    line-height: 62px;
    color: ${colors.gray_700};

    margin-bottom: 8px;

    animation: ${animateTitle} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 0.1s;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: ${colors.gray_50};
    margin-bottom: 24px;
    max-width: 310px;

    > span {
      color: ${colors.primary};
    }

    animation: ${animateSubTitle} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 1s;
  }

  button {
    animation: ${animateButton} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 1.5s;
  }

  ${media.screen('tablet')} {
    height: 50vh;
    max-width: 100%;
    padding: 32px 0;
    align-items: center;
    margin-top: 80px;

    h1 {
      font-size: 32px;
      line-height: 42px;
    }

    h1,
    p {
      max-width: 360px;
      text-align: center;
    }
  }

  ${media.screen('mobile')} {
    height: 50vh;
    max-width: 100%;
    padding: 32px 0;
    align-items: center;
    margin-top: 80px;

    h1 {
      font-size: 32px;
      line-height: 42px;
    }

    h1,
    p {
      max-width: 360px;
      text-align: center;
    }
  }
`;
export const HeroRight = styled.div`
  flex: 1;

  position: relative;

  height: inherit;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .mascote {
    position: absolute;
    left: 5%;
    bottom: 5%;
    z-index: 99;

    animation: ${animateMascote} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 3s;
  }

  .platform {
    animation: ${animatePlatform} 3s cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: backwards;
    animation-delay: 2s;
  }

  ${media.screen('tablet')} {
    justify-content: center;
    align-items: center;
  }

  ${media.screen('mobile')} {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    justify-content: center;
    height: 38%;

    .mascote {
      top: 10%;
      left: 2%;
      width: 30%;
    }

    .platform {
      width: 90%;
      margin-top: -46%;
    }
  }
`;
