import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { database } from '../../services/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

import callIcon from '../../assets/call.svg';
import emailIcon from '../../assets/sms.svg';

import * as Styles from './styles';

interface formData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const formSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório!'),
  email: yup.string().required('E-mail obrigatório!').email('E-mail inválido!'),
  phone: yup.string().required('Telefone obrigatório!'),
  message: yup.string().required('Mensagem obrigatória!'),
});

export function Contact() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({ resolver: yupResolver(formSchema) });

  const handleContact: SubmitHandler<formData> = (data) => {
    const dbRef = collection(database, 'contacts');

    setLoading(true);
    addDoc(dbRef, {
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
    })
      .then((docRef) => {
        setLoading(false);
        toast.success('Dados enviados com sucesso!');
      })
      .catch((error) => {
        toast.error('Erro ao enviar dados!');
      });
  };

  return (
    <Styles.Container id='fale-conosco'>
      <Styles.ContactWrapper>
        <Styles.ContactLeft>
          <span>FALE CONOSCO</span>
          <h2>
            Entre em contato conosco através dos nosso meios de comunicação
          </h2>
          <p>
            Se tiver alguma, dúvida ou solicitação, basta nos enviar uma
            mensagem, nossa equipe entrará em contato o mais rápido possível.
          </p>

          <strong>
            Entre em contato conosco para agendar uma demostração.
          </strong>

          <Styles.ContactSocialsWrapper>
            <Styles.SocialItem>
              <img src={callIcon} alt='Telefone' />
              <p>+55 11 3274-5990</p>
            </Styles.SocialItem>
            <Styles.SocialItem>
              <img src={emailIcon} alt='E-mail' />
              <p>contato@bsine.com</p>
            </Styles.SocialItem>
          </Styles.ContactSocialsWrapper>
        </Styles.ContactLeft>
        <Styles.ContactRight>
          <form onSubmit={handleSubmit(handleContact)}>
            <h5>Preencha os campos abaixo.</h5>
            <p>Nossa equipe entrará em contato o mais breve possível.</p>

            <Styles.InputGroup>
              <label htmlFor='name'>Nome</label>
              <input
                type='text'
                placeholder='Digite seu nome'
                autoCorrect='off'
                autoComplete='off'
                className={errors.name ? 'error' : ''}
                {...register('name')}
              />

              {errors.name && (
                <Styles.ErrorMessage>{errors.name.message}</Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='email'>E-mail</label>
              <input
                type='email'
                placeholder='Digite seu e-mail'
                autoCorrect='off'
                autoComplete='off'
                className={errors.email ? 'error' : ''}
                {...register('email')}
              />
              {errors.email && (
                <Styles.ErrorMessage>
                  {errors.email.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='phone'>Telefone</label>
              <input
                type='text'
                placeholder='Digite seu telefone'
                autoCorrect='off'
                autoComplete='off'
                className={errors.phone ? 'error' : ''}
                {...register('phone')}
              />

              {errors.phone && (
                <Styles.ErrorMessage>
                  {errors.phone.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='message'>Mensagem</label>
              <textarea
                id='message'
                placeholder='Sua mensagem'
                rows={4}
                autoCorrect='off'
                autoComplete='off'
                autoCapitalize='off'
                className={errors.message ? 'error' : ''}
                {...register('message')}
              />

              {errors.message && (
                <Styles.ErrorMessage>
                  {errors.message.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>

            <button>
              {loading ? (
                <ClipLoader loading={loading} color='#fff' size={24} />
              ) : (
                'Enviar'
              )}
            </button>
          </form>
        </Styles.ContactRight>
      </Styles.ContactWrapper>
    </Styles.Container>
  );
}
