import { useCallback, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { ClientCard } from '../Clients';
import cliente01 from '../../assets/user.png';

import * as Styled from './styles';
import { colors } from '../../theme/colors';

export function SectionTestimonial() {
  const [fade, setFade] = useState('');
  const [depositions, setDepositions] = useState([
    {
      id: 1,
      name: 'Marketing',
      cargo: 'Anacapri',
      description:
        'A plataforma Bsine é um facilitador para os departamentos de Marketing. Através do site nossos franqueados conseguem fazer compras de itens homologados pela marca, com agilidade e segurança.',
      avatar_url: cliente01,
    },
  ]);

  const ChangeItemFinalArray = useCallback(() => {
    setFade('fade');

    setTimeout(() => {
      const firstItem = depositions.shift();

      if (firstItem) {
        setDepositions([...depositions, firstItem]);
      }

      setFade('');
    }, 500);
  }, [depositions]);

  return (
    <Styled.Container>
      <Styled.TestimonialWrapper>
        <div className='infos'>
          <span className='customerTestimonial'>Conheça nossos clientes</span>
          <h3>Veja o que nosso clientes pensam sobre nós</h3>
          <p>
            Nosso objetivo é a satifação dos nossos clientes. Veja alguns
            depoimentos feitos por alguns deles.
          </p>
        </div>

        <div className='testimonialSlider'>
          {depositions.length > 1 && (
            <button className='action' onClick={() => ChangeItemFinalArray()}>
              <FaChevronDown size={24} color={colors.gray_700} />
            </button>
          )}

          <div className='cards'>
            {depositions.map((deposition, index) => (
              <ClientCard
                key={index}
                name={deposition.name}
                cargo={deposition.cargo}
                description={deposition.description}
                avatar_url={deposition.avatar_url}
                className={fade && index === 0 ? 'active' : ''}
              />
            ))}
          </div>
        </div>
      </Styled.TestimonialWrapper>
    </Styled.Container>
  );
}
