import { useContext, useState } from 'react';
import { Header } from '../../components/Header';
import { Hero, Fluxo, Sobre, ComoAjudamos } from '../';
import * as Styles from './styles';
import { SectionTestimonial } from '../../components/SectionTestimonial';
import { SectionClients } from '../../components/SectionClients';
import { Contact } from '../../components/Contact';
import { Footer } from '../../components/Footer';
import { Modal } from '../../components/Modal';
import DemoContext from '../../contexts/DemoContext';
import { RiCloseFill } from 'react-icons/ri';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { database } from '../../services/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

interface formData {
  name: string;
  email: string;
  empresa: string;
  phone: string;
  message: string;
}

const formSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório!'),
  email: yup.string().required('E-mail obrigatório!').email('E-mail inválido!'),
  empresa: yup.string().required('Empresa obrigatória!'),
  phone: yup.string().required('Telefone obrigatório!'),
  message: yup.string().required('Mensagem obrigatória!'),
});

export function Layout() {
  const { openModal, handleCloseModal } = useContext(DemoContext);

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({ resolver: yupResolver(formSchema) });

  const handleContact: SubmitHandler<formData> = (data) => {
    const dbRef = collection(database, 'demos');

    setLoading(true);
    addDoc(dbRef, {
      name: data.name,
      email: data.email,
      company: data.empresa,
      phone: data.phone,
      message: data.message,
    })
      .then((docRef) => {
        setLoading(false);
        handleCloseModal();
        toast.success('Dados enviados com sucesso!');
      })
      .catch((error) => {
        toast.error('Erro ao enviar dados!');
      });
  };

  return (
    <Styles.Container>
      <Header />
      <Hero />
      <SectionClients />
      <Fluxo />
      <Sobre />
      <ComoAjudamos />
      <SectionTestimonial />
      <Contact />
      <Footer />

      <Modal
        width='450px'
        height='580px'
        isOpen={openModal}
        overlay='rgba(0,0,0,0.5)'
        title='Teste'
        background='rgba(255,255,255,1)'
        onRequestClose={handleCloseModal}
        borderRadius={4}
      >
        <Styles.ModalHeader>
          <div>
            <h4>Solicite um demonstração</h4>
            <p>Preencha os dados abaixo para solicitar.</p>
          </div>
          <button onClick={handleCloseModal}>
            <RiCloseFill size={20} />
          </button>
        </Styles.ModalHeader>
        <Styles.MainContent>
          <form onSubmit={handleSubmit(handleContact)}>
            <Styles.InputGroup>
              <label htmlFor='name'>Nome</label>
              <input
                type='text'
                placeholder='Digite seu nome'
                autoCorrect='off'
                autoComplete='off'
                className={errors.name ? 'error' : ''}
                {...register('name')}
              />

              {errors.name && (
                <Styles.ErrorMessage>{errors.name.message}</Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='email'>E-mail</label>
              <input
                type='email'
                placeholder='Digite seu e-mail'
                autoCorrect='off'
                autoComplete='off'
                className={errors.email ? 'error' : ''}
                {...register('email')}
              />
              {errors.email && (
                <Styles.ErrorMessage>
                  {errors.email.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='company'>Empresa</label>
              <input
                type='text'
                placeholder='Nome da empresa'
                autoCorrect='off'
                autoComplete='off'
                className={errors.empresa ? 'error' : ''}
                {...register('empresa')}
              />
              {errors.empresa && (
                <Styles.ErrorMessage>
                  {errors.empresa.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='phone'>Telefone</label>
              <input
                type='text'
                placeholder='Digite seu telefone'
                autoCorrect='off'
                autoComplete='off'
                className={errors.phone ? 'error' : ''}
                {...register('phone')}
              />

              {errors.phone && (
                <Styles.ErrorMessage>
                  {errors.phone.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>
            <Styles.InputGroup>
              <label htmlFor='message'>Mensagem</label>
              <textarea
                id='message'
                placeholder='Sua mensagem'
                rows={4}
                autoCorrect='off'
                autoComplete='off'
                autoCapitalize='off'
                className={errors.message ? 'error' : ''}
                {...register('message')}
              />

              {errors.message && (
                <Styles.ErrorMessage>
                  {errors.message.message}
                </Styles.ErrorMessage>
              )}
            </Styles.InputGroup>

            <button>
              {loading ? (
                <ClipLoader loading={loading} color='#fff' size={24} />
              ) : (
                'Enviar'
              )}
            </button>
          </form>
        </Styles.MainContent>
      </Modal>
    </Styles.Container>
  );
}
