import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const Container = styled.div``;
export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
      margin-top: 4px;
      color: ${colors.gray_500};
    }
  }

  button {
    padding: 4px;
    border-radius: 50%;
    border: none;
    font-size: 0;
    background: transparent;

    svg {
      color: ${colors.gray_500};
    }

    transition: all 0.3s ease-in-out;

    &:hover {
      transform: rotate(90deg);
      background: rgba(255, 230, 168, 0.5);
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: ${colors.gray_100};
  }

  input,
  textarea {
    border: none;
    height: 54px;
    background: #f3f3f3;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 14px;
    outline: none;
    font-family: 'Lato', sans-serif;

    transition: border 0.3s;

    &::placeholder {
      color: ${colors.gray_100};
    }

    &:focus {
      border: 1px solid ${colors.primary};
    }
  }

  textarea {
    height: auto;
    padding: 16px 8px;
    resize: vertical;
  }

  input.error,
  textarea.error {
    border: 1px solid crimson;

    &::placeholder {
      color: crimson;
    }
  }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: crimson;
  font-weight: 600;
  margin-top: 4px;
`;

export const MainContent = styled.main`
  margin-top: 32px;
  button {
    border: none;
    height: 56px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
    border-radius: 8px;
    background-color: ${colors.primary};

    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
