import { colors } from '../../theme/colors';
import styled from 'styled-components';
import { media } from '../../theme/breakpoints';

export const Container = styled.div`
  background: ${colors.primary};
  height: 28.438rem;

  position: relative;

  display: flex;
  align-items: center;

  .infos {
    max-width: 24.125rem;
    min-width: 24.125rem;

    .customerTestimonial {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      color: ${colors.white};
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.625rem;
      color: ${colors.gray_700};
    }

    p {
      font-weight: 400;
      line-height: 1.5rem;
      margin-top: 1.5rem;
      color: ${colors.white};
      font-size: 16px;
    }
  }

  ${media.screen('tablet')} {
    flex-direction: column;
    padding: 1.5rem;
    height: auto;

    .infos {
      text-align: center;
      padding: 0 1.5rem;
    }
  }

  ${media.screen('mobile')} {
    flex-direction: column;
    padding: 1.5rem;
    height: auto;

    .infos {
      text-align: center;
      padding: 0 1.5rem;
    }
  }
`;

export const TestimonialWrapper = styled.div.attrs({ className: 'container' })`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 82px 0;

  display: flex;
  align-items: center;

  .testimonialSlider {
    margin-left: 27%;

    display: flex;
    align-items: center;

    .action {
      margin-right: 2rem;
      background: none;
      border: none;
    }

    .cards {
      display: grid;
      column-gap: 2rem;
      grid-template-columns: repeat(6, 30.563rem);
    }
  }

  ${media.screen('tablet')} {
    flex-direction: column;

    .testimonialSlider {
      margin-top: 3rem;
      margin-left: 0;

      .action {
        display: none;
      }

      .cards {
        grid-template-columns: 1fr;
        row-gap: 2rem;
      }
    }
  }

  ${media.screen('mobile')} {
    flex-direction: column;

    .testimonialSlider {
      margin-top: 3rem;
      margin-left: 0;

      .action {
        display: none;
      }

      .cards {
        grid-template-columns: 1fr;
        row-gap: 2rem;
      }
    }
  }
`;
