import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 0;
  background: #ffe6a8;
`;

export const WrapperContent = styled.div`
  width: 100%;
  max-width: 1200px;
  height: inherit;
  margin: 0 auto;

  h3 {
    margin-bottom: 32px;
    text-align: center;
  }
`;

export const SectionClients = styled.div`
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-220px * 7));
    }
  }

  .slider {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 230, 168, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      height: 180px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll 30s linear infinite;
      display: flex;
      width: calc(220px * 22);
    }

    .slide {
      width: 220px;
    }
  }
`;
