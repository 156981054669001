import { createContext, ReactNode, useState } from 'react';

type DemoContextData = {
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  openModal: boolean;
};

type DemoProviderProps = {
  children: ReactNode;
};

const DemoContext = createContext({} as DemoContextData);

export function DemoProvider({ children }: DemoProviderProps) {
  const [openModal, setOpenModal] = useState(false);

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  return (
    <DemoContext.Provider
      value={{
        openModal,
        handleOpenModal,
        handleCloseModal,
      }}
    >
      {children}
    </DemoContext.Provider>
  );
}

export default DemoContext;
