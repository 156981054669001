import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDJnS16I8Dik3gRFAWNv-OYlYOaonAWyYA',
  authDomain: 'bsine-forms.firebaseapp.com',
  databaseURL: 'https://bsine-forms-default-rtdb.firebaseio.com',
  projectId: 'bsine-forms',
  storageBucket: 'bsine-forms.appspot.com',
  messagingSenderId: '602402077660',
  appId: '1:602402077660:web:6948952502120e24382d19',
  measurementId: 'G-C1F54EGJHT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
