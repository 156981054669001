import { Route, Routes as RoutesDOM } from 'react-router-dom';

import { Layout } from '../pages';

export function Routes() {
  return (
    <RoutesDOM>
      <Route index element={<Layout />} />
    </RoutesDOM>
  );
}
