import React, { useState } from 'react';

import Logo from '../../assets/logo.svg';
import { colors } from '../../theme/colors';
import { Button } from '../Button';

import * as Styles from './styles';

const MenuItens = [
  {
    id: 1,
    link: '/',
    name: 'Home',
  },
  {
    id: 2,
    link: '/#sobre',
    name: 'Quem somos',
  },
  {
    id: 3,
    link: '/#como-ajudamos',
    name: 'Como ajudamos',
  },
  {
    id: 4,
    link: '/#fale-conosco',
    name: 'Fale conosco',
  },
];

export function Header() {
  const [toggle, setToggle] = useState(false);

  const [active, setActive] = useState<number | undefined>(1);

  function handleClick(e: React.MouseEvent<HTMLAnchorElement>, id: number) {
    e.stopPropagation();
    setActive(id);
  }

  return (
    <Styles.Container>
      <Styles.HeaderWrapper>
        <img src={Logo} alt='Bsine' />
        <Styles.Nav isActive={toggle}>
          <Styles.MenuWrapper>
            {MenuItens.map((item) => (
              <Styles.MenuItem
                key={item.id}
                className={item.id === active ? 'active' : ''}
              >
                <a href={item.link} onClick={(e) => handleClick(e, item.id)}>
                  {item.name}
                </a>
              </Styles.MenuItem>
            ))}
          </Styles.MenuWrapper>

          <a
            href='https://app.bsine.com/loja/login_page/'
            target='_blank'
            rel='noreferrer'
          >
            <Button
              text='Fazer Login'
              width='130px'
              height='42px'
              background={colors.primary}
              colorText={colors.gray_500}
              isOutlined={false}
              borderRadius='21px'
            />
          </a>
        </Styles.Nav>

        <Styles.Toogle onClick={() => setToggle(!toggle)}>
          <Styles.Bar isActive={toggle} />
          <Styles.Bar isActive={toggle} />
          <Styles.Bar isActive={toggle} />
        </Styles.Toogle>
      </Styles.HeaderWrapper>
    </Styles.Container>
  );
}
