import styled, { css } from 'styled-components';
import { colors } from '../../theme/colors';

export const Container = styled.div`
  width: 100%;
  height: 80px;
  background: ${colors.white};
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 999;

  @media (max-width: 1024px) {
    padding: 0 32px;
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  height: inherit;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Nav = styled.nav<{ isActive: boolean }>`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > a {
    width: 130px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: calc(100vh - 80px);
    position: absolute;
    background: ${colors.white};
    left: 0;
    bottom: 0;
    right: 0;
    top: 80px;
    z-index: 99999999;

    flex-direction: column;

    transform: translateY(100%);

    button {
      margin-bottom: 25%;
    }

    transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    ${({ isActive }) =>
      isActive &&
      css`
        border-top: 0.5px solid #ddd;
        transform: translateY(0);
        transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      `}
  }
`;
export const MenuWrapper = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const MenuItem = styled.li`
  padding: 16px 0;
  list-style: none;

  margin-right: 48px;

  a {
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;

    color: ${colors.gray_300};

    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.6);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    border-bottom: 2px solid ${colors.primary};
  }

  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

export const Toogle = styled.button`
  display: none;

  @media (max-width: 1024px) {
    width: 32px;
    height: 32px;
    display: block;
    border: none;
    background: none;
  }
`;

export const Bar = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background: ${colors.primary};

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2) {
    width: 80%;
    position: relative;
    right: -6px;
  }

  &:nth-child(3) {
    width: 50%;
    position: relative;
    right: -16px;
  }

  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${({ isActive }) =>
    isActive &&
    css`
      &:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 11px;
      }
      &:nth-child(2) {
        width: 100%;
        right: 0;
        transform: translateX(-100%);
        opacity: 0;
      }
      &:nth-child(3) {
        width: 100%;
        right: 0;
        transform: rotate(-45deg);
        position: relative;
        top: -11px;
      }
    `}
`;
