import styled, { css } from 'styled-components';

export const Container = styled.button<{
  width: string;
  height: string;
  colorText: string;
  background: string;
  isOutlined: boolean;
  borderRadius: string;
}>`
  width: 100%;
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ background }) => background};
  color: ${({ colorText }) => colorText};
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius};

  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  ${({ isOutlined, background, colorText }) =>
    isOutlined &&
    css`
      background: none;
      border: 1px solid ${background};
      color: ${background};

      transition: all 0.3s;

      &:hover {
        background: ${background};
        filter: brightness(1);
        color: ${colorText};
      }
    `};
`;
