import styled from 'styled-components';
import { media } from '../../theme/breakpoints';
import { colors } from '../../theme/colors';

export const Container = styled.div`
  width: 100%;

  position: relative;
`;
export const WrapperContent = styled.div`
  width: 100%;
  max-width: 1200px;
  height: inherit;
  margin: 0 auto;
  padding: 82px 0;

  display: flex;
  align-items: center;

  ${media.screen('tablet')} {
    flex-direction: column;
  }
  ${media.screen('mobile')} {
    flex-direction: column;
  }
`;
export const ContentLeft = styled.div`
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.primary};
  }

  h2 {
    max-width: 520px;
    margin: 16px 0 24px 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    color: ${colors.gray_700};
  }

  p {
    font-weight: 400;
    font-size: 17px;
    line-height: 160%;
    color: ${colors.gray_100};
    max-width: 500px;
  }

  img {
    margin-top: 54px;
  }

  ${media.screen('tablet')} {
    text-align: center;
    padding: 0 32px;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
    img {
      width: 100%;
    }
  }

  ${media.screen('mobile')} {
    text-align: center;
    padding: 0 32px;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
    img {
      width: 100%;
    }
  }
`;
export const ContentRight = styled.div`
  width: 50%;
  margin-left: 54px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.screen('tablet')} {
    width: 100%;
    margin-left: 0;
    padding: 0 32px;
  }

  ${media.screen('mobile')} {
    width: 100%;
    margin-left: 0;
    padding: 0 32px;
  }
`;
export const Item = styled.li`
  list-style: none;
  padding: 42px 0;

  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e1e1e6;

  img {
    margin-right: 16px;
  }

  &:last-child {
    border-bottom: none;
  }

  ${media.screen('tablet')} {
    flex-direction: column;
    align-items: center;

    img {
      width: 20%;
      margin-bottom: 16px;
    }
  }

  ${media.screen('mobile')} {
    flex-direction: column;
    align-items: center;

    img {
      width: 20%;
      margin-bottom: 16px;
    }
  }
`;
export const ItemContent = styled.div`
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.gray_700};
  }

  p {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: ${colors.gray_300};
    margin-top: 16px;
  }

  ${media.screen('tablet')} {
    text-align: center;
    span {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      max-width: 320px;
    }
  }

  ${media.screen('mobile')} {
    text-align: center;
    span {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      max-width: 320px;
    }
  }
`;
