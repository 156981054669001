import { HTMLAttributes } from 'react'

import * as Styled from './styles'

interface ClientProps extends HTMLAttributes<HTMLDivElement> {
  name: string
  cargo: string
  description: string
  avatar_url: string
  className?: string
}

export function ClientCard({
  name,
  cargo,
  description,
  avatar_url,
  className
}: ClientProps) {
  return (
    <Styled.Container className={className}>
      <Styled.UserWrapper>
        <Styled.Avatar src={avatar_url} width='67px' height='67px' />
        <Styled.InfosWrapper>
          <span>{name}</span>
          <p>{cargo}</p>
        </Styled.InfosWrapper>
      </Styled.UserWrapper>
      <p className='description'>{description}</p>
    </Styled.Container>
  )
}
