import React from 'react';

import { Modal as ReactModal } from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import * as Styles from './styles';

export interface IModalProps {
  width: number | string;
  height: number | string;
  background: string;
  borderRadius?: number | string;
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  overlay: string;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
}

export function Modal({
  width = 585,
  height = 585,
  background,
  borderRadius,
  title,
  subTitle,
  children,
  isOpen,
  onRequestClose,
  overlay,
  closeOnEsc = true,
  closeOnOverlayClick = true,
}: IModalProps) {
  return (
    <ReactModal
      open={isOpen}
      onClose={onRequestClose}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={false}
      animationDuration={0}
      styles={{
        overlay: {
          backgroundColor: overlay,
        },
        modalContainer: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        modal: {
          width: '100%',
          maxWidth: typeof width === 'number' ? `${width}px` : width,
          height: typeof height === 'number' ? `${height}px` : height,
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
          padding: '32px',
          background,
          overflow: 'hidden',
          borderRadius,
          position: 'relative',
          zIndex: 999999999,
        },
      }}
    >
      <Styles.Container>{children}</Styles.Container>
    </ReactModal>
  );
}
