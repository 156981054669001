import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ToastContainer } from 'react-toastify';
import { DemoProvider } from './contexts/DemoContext';
import { GlobalStyles } from './styles/Global';

function App() {
  return (
    <BrowserRouter>
      <DemoProvider>
        <Routes />
        <GlobalStyles />
        <ToastContainer
          position='bottom-left'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </DemoProvider>
    </BrowserRouter>
  );
}

export default App;
